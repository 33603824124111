import React from "react"
import ThirdStepBar from "../VerticalStepBar/ThirdStepBar"
import {
  Description,
  InputGrid,
  CustomValidationMessage,
  RequiredTriangle,
} from "../FormStyle"
import styled from "styled-components"

const Wrapper = styled.div`
  display: ${props => (props.hidden ? "none" : "block")};
`

class ThirdStep extends React.Component {
  render() {
    const { errors, hidden } = this.props

    const {
      fNameRequire,
      lNameRequire,
      emailRequire,
      emailInvalid,
      phoneRequire,
      phoneInvalid,
      zipcodeRequire,
      zipcodeInvalid,
    } = errors

    return (
      <Wrapper className="form-group" hidden={hidden}>
        <h2>Better Understand your Garages & Get a Quick Quote</h2>
        <Description>
          Tell us your more information so our customer care executives can call
          you and describe our services in a better way.
        </Description>
        <InputGrid>
          <div>
            <input
              type="text"
              name="first_name"
              placeholder="Your First Name"
              onChange={this.props.handleChange}
              pattern="[A-Za-z\s]+$"
            />
            <CustomValidationMessage display={fNameRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomValidationMessage>
          </div>
          <div>
            <input
              type="text"
              name="last_name"
              placeholder="Your Last Name"
              onChange={this.props.handleChange}
              pattern="[A-Za-z\s]+$"
            />
            <CustomValidationMessage display={lNameRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomValidationMessage>
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              onChange={this.props.handleChange}
            />
            <CustomValidationMessage display={emailRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomValidationMessage>
            <CustomValidationMessage display={emailInvalid ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input valid email address</span>
            </CustomValidationMessage>
          </div>
          <div>
            <input
              type="text"
              name="phone_no"
              placeholder="Phone Number"
              onChange={this.props.handleChange}
              pattern="[0-9]{10}"
            />
            <CustomValidationMessage display={phoneRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomValidationMessage>
            <CustomValidationMessage display={phoneInvalid ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input valid phone number</span>
            </CustomValidationMessage>
          </div>
          <div>
            <input
              type="number"
              name="zipcode"
              placeholder="Zip Code"
              onChange={this.props.handleChange}
              pattern="[0-9]+$"
            />
            <CustomValidationMessage display={zipcodeRequire ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input this field</span>
            </CustomValidationMessage>
            <CustomValidationMessage display={zipcodeInvalid ? "block" : "none"}>
              <RequiredTriangle />
              <span>Please input valid Zipcode</span>
            </CustomValidationMessage>
          </div>
        </InputGrid>
        <ThirdStepBar fill="white" />
      </Wrapper>
    )
  }
}

export default ThirdStep
