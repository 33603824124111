import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import axios from "axios"
import FirstStep from "./first-step"
import SecondStep from "./second-step"
import ThirdStep from "./third-step"
import * as EmailValidator from "email-validator"
import {
  ProductFormWrapper,
  LeftWrapper,
  RightWrapper,
  Triangle,
  ButtonWrapper,
  Button,
} from "../FormStyle"
import { phone } from "phone"
import { postcodeValidator } from "postcode-validator"

const ImageWrap = styled.div`
  display: ${props => props.display};
  & img {
    width: 100%;
    margin-bottom: -50px;
  }
`

const ActionButton = styled(Button)`
  margin: 0;
`

const FormButtonWrapper = styled(ButtonWrapper)`
  position: absolute;
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  gap: 20px;
`

class ProductCustomBuildingForm extends React.Component {
  constructor(props) {
    super(props)
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      rooftype: "Vertical Roof",
      backend: "",
      frontend: "",
      leftside: "",
      rightside: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      zipcode: "",
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      zipcodeRequire: false,
      errors: {}
    }
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this._next = this._next.bind(this)
    this._previous = this._previous.bind(this)
  }

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target
    this.setState({
      [name]: value,
    })
    if (name === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (name === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (name === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (name === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (name === "zipcode") {
      this.setState({
        zipcodeRequire: false,
      })
    }
  }

  // Trigger an alert on form submission
  handleSubmit = event => {
    let currentStep = this.state.currentStep
    const { buildingType, location } = this.props
    event.preventDefault()
    let errors = {}
    if (this.state.first_name === "") {
      errors.fNameRequire = true
    }

    if (this.state.last_name === "") {
      errors.lNameRequire = true
    }

    if (this.state.email === "") {
      errors.emailRequire = true
    } else if (!EmailValidator.validate(this.state.email)) {
      errors.emailInvalid = true
    }

    if (this.state.phone_no === "") {
      errors.phoneRequire = true
    } else if (!phone(this.state.phone_no, { country: "USA" }).isValid) {
      errors.phoneInvalid = true
    }

    if (this.state.zipcode === "") {
      errors.zipcodeRequire = true
    } else if (!postcodeValidator(this.state.zipcode, "US")) {
      errors.zipcodeInvalid = true
    }

    if (Object.keys(errors).length) {
      this.setState({ errors });
    } else {
      const comment =
        "Building Type: " +
        buildingType +
        "; Roof Style: " +
        this.state.rooftype +
        "; Width: " +
        this.state.width +
        "; Length: " +
        this.state.length +
        "; Height: " +
        this.state.height +
        "; Back End: " +
        this.state.backend +
        "; Front End: " +
        this.state.frontend +
        "; Left Side: " +
        this.state.leftside +
        "; Right Side: " +
        this.state.rightside
      const data = {
        source: location.origin,
        form: "product custom building form",
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        zipcode: this.state.zipcode,
        comment: comment,
      }
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          currentStep = currentStep + 1
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            currentStep: currentStep,
          })
          navigate("/thank-you-request-quote/")
        })
        .catch(error => {
          alert(error)
        })
    }
  }

  _next() {
    let currentStep = this.state.currentStep
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 3 ? 4 : currentStep + 1
    this.setState({
      currentStep: currentStep,
    })
  }

  _previous() {
    let currentStep = this.state.currentStep
    this.setState({
      currentStep: currentStep - 1,
    })
  }

  get previousButton() {
    if (this.state.currentStep === 1) return null
    return (
      <ActionButton width="320px" type="button" onClick={this._previous}>
        <div>Previous</div>
      </ActionButton>
    )
  }

  get nextButton() {
    let currentStep = this.state.currentStep
    // If the current step is not 3, then render the "next" button
    if (currentStep < 3) {
      return (
        <ActionButton width="320px" type="button" onClick={this._next}>
          <div>Next</div>
        </ActionButton>
      )
    } else if (currentStep === 3) {
      return (
        <ActionButton
          width="320px"
          type="button"
          form="product-form"
          onClick={this.handleSubmit}
        >
          <div>Submit</div>
        </ActionButton>
      )
    }

    return null
  }

  render() {
    const { data, category } = this.props
    const { currentStep, errors } = this.state

    return (
      <div>
        <ProductFormWrapper>
          <LeftWrapper>
            <form id="product-form">
              <FirstStep
                handleChange={this.handleChange}
                rooftype={this.state.rooftype}
                data={data}
                hidden={currentStep !== 1}
              />
              <SecondStep
                hidden={currentStep !== 2}
                handleChange={this.handleChange}
                rooftype={this.state.rooftype}
                backend={this.state.backend}
                frontend={this.state.frontend}
                rightside={this.state.rightside}
                leftside={this.state.leftside}
              />
              <ThirdStep
                hidden={currentStep !== 3}
                handleChange={this.handleChange}
                first_name={this.state.first_name}
                last_name={this.state.last_name}
                email={this.state.email}
                phone_no={this.state.phone_no}
                zipcode={this.state.zipcode}
                errors={errors}
                fNameRequire={this.state.fNameRequire}
                lNameRequire={this.state.lNameRequire}
                emailRequire={this.state.emailRequire}
                phoneRequire={this.state.phoneRequire}
                zipcodeRequire={this.state.zipcodeRequire}
              />
              <FormButtonWrapper bottom="71px" textAlign="left">
                {this.previousButton}
                {this.nextButton}
              </FormButtonWrapper>
            </form>
            <Triangle />
          </LeftWrapper>
          <RightWrapper>
            <h2>{`${this.state.rooftype} ${category}`}</h2>
            {data.features.map(item => {
              return (
                <ImageWrap
                  display={item.roof === this.state.rooftype ? "block" : "none"}
                >
                  <img src={item.image} alt="features" />
                </ImageWrap>
              )
            })}
          </RightWrapper>
        </ProductFormWrapper>
      </div>
    )
  }
}

export default ProductCustomBuildingForm
