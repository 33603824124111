import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Section,
  BreadCrumb,
  SectionTitle,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import {
  HeroSection,
  HeroCover,
  LeftHero,
  RightHero,
  HeroPageTitle,
  HeroDesc,
  HeroFormWrap,
  HeroFormTitle,
  HeroFormBody,
} from "../components/Hero"
import TabProductCardList from "../components/TabProductCardList"
import CallToAction from "../components/CallToAction"
import DesignPlan from "../components/DesignPlan"
import AccreditedBusiness from "../components/AccreditedBusiness"

import ProductCustomBuildingForm from "../components/ProductCustomBuildingForm"
import generateHTML from "../utils/generateHTML"
import QuickContactForm from "../components/QuickContactForm"
import ProductCardList from "../components/ProductCardList"

class ProductCategoryTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulProductCategoryLanding
    const productData = this.props.data.allContentfulProduct
    const location = this.props
    //const getUrlEndPoint =  location.path
    let currentData = []
    productData.edges.forEach(edge => {
      if (edge.node.productCategory.name === pageData.category.name) {
        currentData.push(edge)
      }
    })

    return (
      <Layout location={this.props.location}>
        <SEO
          title={pageData.metaTitle}
          description={pageData.metaDescription}
        />

        <HeroSection>
          <div className="container">
            <BreadCrumb className="static">
              <Link to="/">Home </Link> /{" "}
              <span>
                {pageData.category.name === "Commercial Buildings"
                  ? "Commercial Metal Buildings"
                  : pageData.category.name}
              </span>
            </BreadCrumb>

            <HeroCover>
              <LeftHero>
                <HeroPageTitle>{pageData.heroTitle}</HeroPageTitle>
                <HeroDesc
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroDescription.childMarkdownRemark.html,
                  }}
                />
              </LeftHero>
              <RightHero>
                <HeroFormWrap>
                  <HeroFormTitle>Get In Touch</HeroFormTitle>
                  <HeroFormBody>
                    <QuickContactForm
                      location={this.props.location}
                      formName={`${pageData.category.name} Contact Form`}
                    />
                  </HeroFormBody>
                </HeroFormWrap>
              </RightHero>
            </HeroCover>
          </div>
        </HeroSection>

        {pageData.formSection &&
          pageData.formSection.features.length > 0 &&
          pageData.formSection.features[0].roof === "Vertical Roof" && (
            <ProductCustomBuildingForm
              data={pageData.formSection}
              buildingType={pageData.category.name}
              location={this.props.location}
              category={pageData.category.name}
            />
          )}
        {currentData.length !== 0 && (
          <Section pt="95px" pb="90px" bg="#F4FBFF">
            <SectionTitle>{pageData.productListTitle}</SectionTitle>
            <div className="container">
              {pageData.category.name === "Commercial Buildings" ||
              pageData.category.name === "Storage Buildings" ||
              pageData.category.name === "Metal Warehouses" ? (
                <ProductCardList
                  data={currentData}
                  location={this.props.location}
                />
              ) : (
                <TabProductCardList
                  data={currentData}
                  category={pageData.category.name}
                  location={this.props.location}
                />
              )}
            </div>
          </Section>
        )}

        <CallToAction location={location} />
        <Section pt="90px" pb="90px">
          <div className="container">
            <LeftPannel>
              <MarkdownContent
                dangerouslySetInnerHTML={{
                  __html: generateHTML(pageData.content.content),
                }}
              />
            </LeftPannel>
            <RightPannel>
              <DesignPlan />
              <AccreditedBusiness />
            </RightPannel>
          </div>
        </Section>
      </Layout>
    )
  }
}

export default ProductCategoryTemplate

export const pageQuery = graphql`
  query categoryLandigQuery($id: String!) {
    contentfulProductCategoryLanding(id: { eq: $id }) {
      title
      metaTitle
      metaDescription
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      productListTitle
      formSection {
        title
        features {
          title
          roof
          image
          description
        }
      }
      category {
        name
      }
      content {
        content
      }
    }
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          price
          priceDescription
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
        }
      }
    }
  }
`
