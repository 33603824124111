import React from "react"
import WidthIcon from "../Icons/WidthIcon"
import LengthIcon from "../Icons/LengthIcon"
import HeightIcon from "../Icons/HeightIcon"
import SecondStepBar from "../VerticalStepBar/SecondStepBar"
import { Dimensions, DimensionItem, Value, SideGrid } from "../FormStyle"
import styled from "styled-components"

const Wrapper = styled.div`
  display: ${props => (props.hidden ? "none" : "block")};
`

class SecondStep extends React.Component {
  render() {
    const { hidden } = this.props

    return (
      <Wrapper className="form-group" hidden={hidden}>
        <h2>Better Understand your Garages & Get a Quick Quote</h2>
        <h5>Choose the Dimensions</h5>
        <Dimensions>
          <DimensionItem>
            <div>
              <WidthIcon />
            </div>
            <Value>
              <p>Width</p>
            </Value>
            <input
              type="number"
              name="width"
              onChange={this.props.handleChange}
            />
          </DimensionItem>
          <DimensionItem>
            <div>
              <LengthIcon />
            </div>
            <Value>
              <p>Length</p>
            </Value>
            <input
              type="number"
              name="length"
              onChange={this.props.handleChange}
            />
          </DimensionItem>
          <DimensionItem>
            <div>
              <HeightIcon />
            </div>
            <Value>
              <p>Height</p>
            </Value>
            <input
              type="number"
              name="height"
              onChange={this.props.handleChange}
            />
          </DimensionItem>
        </Dimensions>
        <SideGrid>
          <div>
            <h5>Back Ends</h5>
            <label>
              <input
                type="radio"
                name="backend"
                value="Open"
                onChange={this.props.handleChange}
              />
              Open{" "}
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="backend"
                value="Fully Enclose"
                onChange={this.props.handleChange}
              />
              Fully Enclose
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="backend"
                value="Gable Ends"
                onChange={this.props.handleChange}
              />
              Gable Ends
            </label>
            <br />
          </div>
          <div>
            <h5>Front Ends</h5>
            <label>
              <input
                type="radio"
                name="frontend"
                value="Open"
                onChange={this.props.handleChange}
              />
              Open{" "}
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="frontend"
                value="Fully Enclose"
                onChange={this.props.handleChange}
              />
              Fully Enclose
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="frontend"
                value="Gable Ends"
                onChange={this.props.handleChange}
              />
              Gable Ends
            </label>
            <br />
          </div>
          <div>
            <h5>Left Side</h5>
            <label>
              <input
                type="radio"
                name="leftside"
                value="Open"
                onChange={this.props.handleChange}
              />
              Open{" "}
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="leftside"
                value="Fully Enclose"
                onChange={this.props.handleChange}
              />
              Fully Enclose
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="leftside"
                value="Gable Ends"
                onChange={this.props.handleChange}
              />
              Gable Ends
            </label>
            <br />
          </div>
          <div>
            <h5>Right Side</h5>
            <label>
              <input
                type="radio"
                name="rightside"
                value="Open"
                onChange={this.props.handleChange}
              />
              Open{" "}
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="rightside"
                value="Fully Enclose"
                onChange={this.props.handleChange}
              />
              Fully Enclose
            </label>
            <br />
            <label>
              <input
                type="radio"
                name="rightside"
                value="Gable Ends"
                onChange={this.props.handleChange}
              />
              Gable Ends
            </label>
            <br />
          </div>
        </SideGrid>
        <SecondStepBar fill="white" />
      </Wrapper>
    )
  }
}

export default SecondStep
