import React from "react"
import styled from "styled-components"
import FirstStepBar from "../VerticalStepBar/FirstStepBar"
import { Types, Label } from "../FormStyle"

const Wrapper = styled.div`
  display: ${props => (props.hidden ? "none" : "block")};
`

const Description = styled.div`
  display: ${props => props.display};
  & h5 {
    margin-top: 20px;
  }
  & p {
    margin-bottom: 15px;
  }
`
class FirstStep extends React.Component {
  render() {
    const { data, hidden, rooftype } = this.props

    return (
      <Wrapper className="form-group" hidden={hidden}>
        <h2>{data.title}</h2>
        <h5>Select a Roof Style</h5>
        <Types>
          <Label
            bg={rooftype === "Vertical Roof" ? "#EA5F2C" : "none"}
            borderColor={rooftype === "Vertical Roof" ? "#EA5F2C" : "#FFFFFF33"}
            color={rooftype === "Vertical Roof" ? "white" : "#FFFFFF33"}
          >
            <input
              type="radio"
              name="rooftype"
              value="Vertical Roof"
              onChange={this.props.handleChange}
            />
            Vertical Roof
          </Label>
          <Label
            bg={rooftype === "A-Frame Roof" ? "#EA5F2C" : "none"}
            borderColor={rooftype === "A-Frame Roof" ? "#EA5F2C" : "#FFFFFF33"}
            color={rooftype === "A-Frame Roof" ? "white" : "#FFFFFF33"}
          >
            <input
              type="radio"
              name="rooftype"
              value="A-Frame Roof"
              onChange={this.props.handleChange}
            />
            A-Frame Roof
          </Label>
          <Label
            bg={rooftype === "Regular Roof" ? "#EA5F2C" : "none"}
            borderColor={rooftype === "Regular Roof" ? "#EA5F2C" : "#FFFFFF33"}
            color={rooftype === "Regular Roof" ? "white" : "#FFFFFF33"}
          >
            <input
              type="radio"
              name="rooftype"
              value="Regular Roof"
              onChange={this.props.handleChange}
            />
            Regular Roof
          </Label>
        </Types>
        <div>
          {data.features.map(item => {
            return (
              <Description display={item.roof === rooftype ? "block" : "none"}>
                <h5>{item.title}</h5>
                <p>{item.description}</p>
              </Description>
            )
          })}
        </div>
        <FirstStepBar fill="white" />
      </Wrapper>
    )
  }
}

export default FirstStep
